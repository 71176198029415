import React, { useEffect, useState, useCallback } from 'react';

const defaultChars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function TextScramble({
  children,
  duration = 0.8,
  speed = 0.04,
  characterSet = defaultChars,
  className,
  as: Component = 'span',
  trigger = true,
  onScrambleComplete,
  ...props
}) {
  const [displayText, setDisplayText] = useState(children);
  const [isAnimating, setIsAnimating] = useState(false);
  const text = children;

  const scramble = useCallback(async () => {
    if (isAnimating) return;
    setIsAnimating(true);

    const steps = duration / speed;
    let step = 0;

    const interval = setInterval(() => {
      let scrambled = '';
      const progress = step / steps;

      for (let i = 0; i < text.length; i++) {
        if (text[i] === ' ') {
          scrambled += ' ';
          continue;
        }

        if (progress * text.length > i) {
          scrambled += text[i];
        } else {
          scrambled +=
            characterSet[Math.floor(Math.random() * characterSet.length)];
        }
      }

      setDisplayText(scrambled);
      step++;

      if (step > steps) {
        clearInterval(interval);
        setDisplayText(text);
        setIsAnimating(false);
        if (onScrambleComplete) {
          onScrambleComplete();
        }
      }
    }, speed * 1000);
  }, [text, duration, speed, characterSet, isAnimating, onScrambleComplete]);

  useEffect(() => {
    if (!trigger) return;

    scramble();
  }, [trigger, scramble]);

  const ComponentToRender = Component;

  return (
    <ComponentToRender className={className} {...props}>
      {displayText}
    </ComponentToRender>
  );
} 