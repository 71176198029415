import React, { useState } from "react";

import { Heading, Box, Text, Flex } from "theme-ui";
import { Link } from "gatsby";

import ArrowButton from "./arrowbutton";
import { TextScramble } from "./ui/text-scramble";

const Navlink = ({ children, to, external = false }) => {
  const button = (
    <ArrowButton color="accent" size={"sm"}>
      {children}
    </ArrowButton>
  );

  return (
    <Flex
      sx={{ pr: [2, 4, 4, 4], alignContent: "center", alignItems: "center", justifyContent: "center" }}
    >
      {external ? (
        <a href={to} target="_blank">
          {" "}
          {button}{" "}
        </a>
      ) : (
        <Link to={to}> {button} </Link>
      )}
    </Flex>
  );
};

const Navbar = () => {
  const [isTrigger, setIsTrigger] = useState(false);
  
  return (
  <Box
    sx={{
      mt: 3,
      pb: 3,
    }}
  >
    <Flex>
      <Heading
        sx={{
          fontSize: [2, 2, 2, 3],
          fontFamily: "\"JetBrains Mono\", monospace",
          fontWeight: 500
        }}
      >
        <Link to={"/"}>
          <Text sx={{ color: "#FF5733" }}
                onMouseEnter={() => setIsTrigger(true)}
                onMouseLeave={() => setIsTrigger(false)}>
            <TextScramble
              as="span"
              trigger={isTrigger}
              duration={0.6}
              speed={0.02}
              characterSet="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789/>"
            >
              /home/pratham
            </TextScramble>
          </Text>
        </Link>
      </Heading>
      <Box sx={{ mx: "auto" }}></Box>
      <Navlink to="/projects"> Projects </Navlink>
      <Navlink
        external={true}
        // to="https://drive.google.com/file/d/1ZD8aUJoFAge0zOPlGA5ECel8Ungi24b-/view?usp=sharing"
        to="https://x.com/prathami11"
      >
        X/Twitter
      </Navlink>
    </Flex>

    <Box mt={[1, 3, 3, 3]} mb={[1, 2, 2, 2]}>
      <Text
        sx={{ fontSize: [1, null, null, null], letterSpacing: "smallcaps" }}
      >
        CS @ UIUC
      </Text>
    </Box>
  </Box>
  );
};

export default Navbar;
